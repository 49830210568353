
import { Options } from 'vue-class-component'
import Api from '@/api/index'
import { SuperVue } from '@/mixins/SuperVue'
import PagePlugin from '@/components/page-plugin/page-plugin.vue'
import Utils from '@/utils'
import CustomDialog from '@/components/custom-dialog/custom-dialog.vue'
import ScoreScaleVue from '@/components/score-scale.vue'
import { httpRequest } from '@/plugins'

@Options({
  components: {
    PagePlugin,
    CustomDialog,
    ScoreScaleVue
  }
})
export default class patent_manage extends SuperVue {
  buttonShow(name) {
    return Utils.showBtn(name)
  }
  tb = { page: 1, pageSize: 10 }
  pageChange({ currentPage, pageSize }) {
    this.tb.pageSize = pageSize;
    this.tb.page = currentPage;
  }
  //  导出
  selection = [] as any
  exportData() {
    if (this.selection.length === 0) return;
    httpRequest
      .post(
        'api/v1/excelExport/exportPatentManage',
        {
          ids: this.selection.map(item => item.id)
        },
        { responseType: 'blob' }
      )
      .then((res: any) => {
        const blob = new Blob([res], { type: 'application/vnd.ms-excel' }) // type是文件类，详情可以参阅blob文件类型
        // 创建新的URL并指向File对象或者Blob对象的地址
        const blobURL = window.URL.createObjectURL(blob)
        // 创建a标签，用于跳转至下载链接
        const tempLink = document.createElement('a')
        tempLink.style.display = 'none'
        tempLink.href = blobURL
        tempLink.setAttribute('download', `专利数据-${ new Date() }`)
        // 兼容：某些浏览器不支持HTML5的download属性
        if (typeof tempLink.download === 'undefined') {
          tempLink.setAttribute('target', '_blank')
        }
        // 挂载a标签
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
        // 释放blob URL地址
        window.URL.revokeObjectURL(blobURL)
      })
  }
  selectionChange(selection) {
    this.selection = selection
  }
  // 评分标准弹窗逻辑-start
  scoreScaleData = {
    open: false,
    evaluation_criterion: '',
    weightItems: []
  }
  get_evaluation_criterion(id: number): void {
    Api.http_get_evaluation_criterion({ id: id }).then((res) => {
      if (res.code === '200') {
        this.scoreScaleData.evaluation_criterion = res.data.evaluation_criterion
        this.scoreScaleData.weightItems = res.data.weightItems
        this.scoreScaleData.open = true
      }
    })
  }
  // end
  opinionListIndex = 0

  t_all_data = []

  t_total = 0
  t_search_data = {
    start_time: '',
    end_time: '',
    name: '',
    evaluation_leader: '',
    status: '',
    order_by: '',
    sort: ''
  }

  rule_t_search_data = {}

  t_detail_dialog = {
    open: false,
    title: '详情'
  }

  t_detail_data = {
    person_info: {
      dept: '',
      contact: '',
      phone: '',
      duty: '',
      address: '',
      email: '',
      inventor: [] as any[]
    },
    base_info: {
      name: '',
      type: '',
      industry_involved: '',
      related_industry: '',
      patentee: '',
      result_type: '' as string | number,
      has_others: '' as string | number,
      others_msg: [] as any,
      digest: '', // 摘要
      digest_file: [] as any,
      claim_rights: '', // 权利要求书
      claim_rights_file: [] as any,
      specification: '', // 说明书
      specification_file: [] as any
    },
    supplement: {
      significance: '',
      question: '',
      plan: '',
      other: ''
    },
    conversion_situation: {
      conditions: '',
      // expect: '',
      expect: [] as any,
      max_price: 0,
      min_price: 0
    },
    self_info: [],
    opinionList: [] as any[]
  }

  t_edit_dialog = {
    open: false,
    title: '指派评估组长'
  }

  t_edit_data = {
    id: '',
    evaluation_leader_id: 0
  }

  rule_t_edit_data = {}

  getLeaderList: any[] = []
  status_766 = new Map<any, string>([
    [0, '草稿'],
    [1, '待发明人确认'],
    [2, '待指派组长'],
    [3, '待分配专家'],
    [4, '待专家评'],
    [5, '待组长审核'],
    [6, '审核通过'],
    [7, '审核不通过'],
    [8, '待评估机构确认'],
    [9, '成员退回'],
    [10, '评估机构退回'],
    [11, '报告待审核'],
    [12, '报告退回']
  ])
  type_100 = new Map<any, string>([
    [1, '发明'],
    [2, '实用新型'],
    [3, '外观设计']
  ])
  //
  type_686 = new Map<any, string>([
    [1, '基础研究'],
    [2, '应用研究'],
    [3, '技术开发与产业化']
  ])
  type_696 = new Map<any, string>([
    [1, '否'],
    [2, '是']
  ])

  t_get_all_data(offset = 0, length = 10) {
    const postData = {
      offset: offset || 0,
      length: length || 10,
      start_time: this.t_search_data.start_time,
      end_time: this.t_search_data.end_time,
      name: this.t_search_data.name,
      evaluation_leader: this.t_search_data.evaluation_leader,
      status: this.t_search_data.status,
      dept_id:
        this.$store.state.curRoleId === 1
          ? null
          : this.$store.state.userInfo.dept_id,
      patent_type: 2,
      order_by: this.t_search_data.order_by,
      sort: this.t_search_data.sort
    }
    Api.http_patent_managet0(postData).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.t_all_data = res.data.data
        this.t_total = res.data.total
      }
    })
  }
  t_detail_init(row) {
    Api.http_patent_managetget4({ id: row.id }).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.t_detail_data.person_info = res.data.person_info
        this.t_detail_data.base_info = res.data.base_info
        this.t_detail_data.supplement = res.data.supplement
        this.t_detail_data.conversion_situation = res.data.conversion_situation
        this.t_detail_data.self_info = res.data.self_info
        this.t_detail_data.opinionList = res.data.opinionList
        this.t_detail_dialog.open = true
        this.setDialogName(this.t_detail_dialog.title)
        if (Utils.isJSON(this.t_detail_data.base_info.others_msg)) {
          this.t_detail_data.base_info.others_msg = JSON.parse(
            this.t_detail_data.base_info.others_msg
          )
        }
        if (Utils.isJSON(this.t_detail_data.conversion_situation.expect)) {
          this.t_detail_data.conversion_situation.expect = JSON.parse(
            this.t_detail_data.conversion_situation.expect
          )
        }
        if (Utils.isJSON(this.t_detail_data.base_info.claim_rights_file)) {
          this.t_detail_data.base_info.claim_rights_file = JSON.parse(
            this.t_detail_data.base_info.claim_rights_file
          )
        }
        if (Utils.isJSON(this.t_detail_data.base_info.digest_file)) {
          this.t_detail_data.base_info.digest_file = JSON.parse(
            this.t_detail_data.base_info.digest_file
          )
        }
        if (Utils.isJSON(this.t_detail_data.base_info.specification_file)) {
          this.t_detail_data.base_info.specification_file = JSON.parse(
            this.t_detail_data.base_info.specification_file
          )
        }
      }
    })
  }
  t_detail_cancle() {
    this.t_detail_dialog.open = false
    this.removeDialogName()
  }
  t_detail_ok() {
    this.t_detail_dialog.open = false
    this.removeDialogName()
  }
  t_edit_init(row) {
    this.get_getLeaderList(row.id)
    Api.http_patent_managetget5({ id: row.id }).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.t_edit_data.id = row.id
        this.t_edit_data.evaluation_leader_id = res.data.evaluation_leader_id
        this.t_edit_dialog.open = true
      }
    })
  }
  t_edit_cancle() {
    this.t_edit_dialog.open = false
  }
  t_edit_ok() {
    this.$refs.ref_t_edit_data.validate((valid) => {
      if (valid) {
        const postData = {
          id: this.t_edit_data.id,
          evaluation_leader_id: this.t_edit_data.evaluation_leader_id
        }
        Api.http_patent_managet5(postData).then((res) => {
          if (res.code !== '200') {
            this.$message.error(res.message)
          } else {
            this.t_edit_dialog.open = false
            this.t_get_all_data((this.tb.page - 1) * this.tb.pageSize, this.tb.pageSize)
            this.$message.success(JSON.stringify(res.message))
          }
        })
      }
    })
  }

  get_getLeaderList(id = -1) {
    Api.http_patent_managetgetLeaderList0({
      id: id,
      offset: 0,
      length: 999
    }).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.getLeaderList = res.data.data
      }
    })
  }
  created() {
    this.t_get_all_data(0, 10)
  }
}
