
import { defineComponent, ref, watchEffect, watch } from '@vue/runtime-core';
export default defineComponent({
  name: 'ScoreScaleVue',
  props: {
    modelValue: Boolean,
    evaluation_criterion: String,
    weightItems: Array
  },
  emits: ['update:modelValue'],
  setup(props, context) {
    const show = ref(props.modelValue)
    watchEffect(() => { context.emit('update:modelValue', show.value) })
    watchEffect(() => { show.value = props.modelValue })
    function getColorClass(score:number):string {
      if (score > 7) return 'red';
      if (score < 5) return 'orange';
      return 'blue';
    }

    return { show, getColorClass }
  }
})
