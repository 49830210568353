
import { Options } from 'vue-class-component'
import { SuperVue } from '@/mixins/SuperVue'
@Options({
  props: {
    modal: {
      type: Boolean,
      required: true
    }
  },
  watch: {
    modal(val) {
      return val
    }
  }
})
export default class CustomDialog extends SuperVue {
  modal!:boolean
}
